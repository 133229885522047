<template>
  <div class="rewardBox">
    <el-dialog title="确认删除" :visible.sync="ifshow" width="798px" center>
      <div class="box">
        <div class="text">
          要删除此视频吗？删除视频后你可以在回收箱查看该视频。
        </div>
      </div>
      <div class="boxIte">
        <slot> </slot>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clackOk()">确认删除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Store from "../../../store";
export default {
  props: {
    it: {
      type: Object,
    },
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      // 是否显示
      ifshow: false,
      act: "10",
      // 是否打开输入
      inputShow: false,
      web3: "",
      shangCont: "",
    };
  },
  methods: {
    up() {
      this.ifshow = true;
    },
    down() {
      this.ifshow = false;
    },
    clackOk() {
      console.log("132");
      this.$emit("clackok");
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  /* 矩形 20 */

  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  /* 矩形 20 */
  width: 798px;
  padding-bottom: 30px;
  // height: 711px;
  .box {
    width: 664px;
    margin: 0 auto;
    .user {
      display: flex;
      align-items: center;
      .text {
        margin-left: 13px;
        .name {
          height: 35px;
          font-family: SourceHanSansCN-Regular;
          font-size: 24px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #333333;
        }
        .addr {
          height: 27px;
          font-family: SourceHanSansCN-Regular;
          font-size: 16px;
          font-weight: normal;
          letter-spacing: 0em;
          color: #999999;
        }
      }
    }
    .amountBox {
      display: flex;
      justify-content: space-between;
      margin-top: 49px;
      .amoutBut {
        width: 170px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #ff7d41;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        /* 10 PSC */

        font-family: SourceHanSansCN-Medium;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0em;
        color: #ff7d41;
      }
      .act {
        background-color: #ff7d41;
        color: #ffffff;
      }
    }
    .el-input {
      margin-top: 49px;
      /* 矩形 23 */
      height: 180px;
      border-radius: 5px;
      box-sizing: border-box;
      // border: 1px solid #ff7d41;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      input {
        height: 90px;
        outline: none;
        padding-right: 20px;
        padding-left: 39px;
        font-size: 20px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      input:focus {
        border: 1px solid #ff7d41;
      }
      .el-input__suffix {
        right: 50px;
        font-size: 20px;
      }
    }
  }
  .el-button {
    width: 664px;
    height: 80px;
    font-size: 24px;
    background-color: #ff7d41;
    border: none;
  }
  .hui {
    background-color: #999;
  }
}
.text {
  /* 要删除此视频吗？删除视频后你可以在回收箱查看该视频。 */

  height: 35px;
  font-family: SourceHanSansCN-Regular;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #333333;
}
.boxIte {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
</style>
