<template>
  <div class="viewBox">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部视频" name="first"> </el-tab-pane>
      <el-tab-pane label="审核中" name="second"> </el-tab-pane>
      <el-tab-pane label="未通过" name="third"> </el-tab-pane>
      <el-tab-pane label="已删除" name="third4"> </el-tab-pane>
    </el-tabs>
    <div class="videoList">
      <item-box
        @updete="getMyVideo()"
        :it="item"
        v-for="item in store.myVideoList"
        :key="item.id"
        :type="Number(act)"
      ></item-box>
    </div>
    <el-pagination
      background
      hide-on-single-page
      @current-change="handleCurrentChange"
      :current-page.sync="pageNum"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import Store from "../../../store";
import ItemBox from "./itemBox.vue";
export default {
  components: { ItemBox },
  mounted() {
    this.getMyVideo();
  },
  data() {
    return {
      store: Store.state,
      activeName: "first",
      act: 0,

      total: 0,
      pageNum: 1,
      pageSize: 10,
    };
  },
  methods: {
    // 选中了某一项
    handleClick(tab) {
      // console.log(tab.index);
      this.act = tab.index;
      this.getMyVideo();
    },
    // 获取我的视频
    async getMyVideo() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post("/userInfo/videos", {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.act,
        userId: this.store.user.userId,
      });
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.store.myVideoList = res.data.records;
      this.total = Number(res.data.total);
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getMyVideo();
    },
  },
};
</script>

<style lang="less" scoped>
.viewBox {
  .actList {
    display: flex;
  }
  .videoList {
    margin-bottom: 50px;
  }
}
</style>
