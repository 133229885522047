<template>
  <div class="itemBox">
    <div class="ind" @click="goVideoDec()">
      <div class="start" v-if="it.authStatus == 2 || it.authStatus == 0">
        <svg
          v-if="it.authStatus == 0"
          class="icon svg-icon in"
          aria-hidden="true"
        >
          <use xlink:href="#icon-shenhezhong"></use>
        </svg>
        <!-- 未通过 -->
        <svg
          v-if="it.authStatus == 2"
          class="icon svg-icon err"
          aria-hidden="true"
        >
          <use xlink:href="#icon-weitongguo"></use>
        </svg>
      </div>
      <img :src="it.videoThumbnail" alt="" />
      <!-- <div class="time">23:45</div> -->
    </div>
    <div class="dec">
      <div class="p">
        {{ it.videoTitle }}
      </div>
      <div class="time">上传时间：{{ uti.changeTime(it.createTime) }}</div>
      <div class="ft">
        <div class="l">
          <div class="item">播放量：{{ it.playNum }}</div>
          <div class="item">点赞量：{{ it.loveNum }}</div>
          <div class="item">打赏收入：{{ it.rewardNum }}</div>
        </div>
        <div class="r">
          <!-- <div class="item">
            <svg @click="addLove()" class="sc icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang"></use>
            </svg>
            <svg @click="addLove()" class="sc icon svg-icon" aria-hidden="true">
              <use xlink:href="#icon-shoucang_shixin"></use>
            </svg>
          </div> -->
          <div class="item">
            <svg
              @click.stop="updateVideo()"
              v-if="type == 3"
              class="icon svg-icon"
              aria-hidden="true"
            >
              <use xlink:href="#icon-yihuifu-xiugai"></use>
            </svg>
            <svg
              @click.stop="upDelet()"
              v-else
              class="icon svg-icon"
              aria-hidden="true"
            >
              <use xlink:href="#icon-shanchu"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <delete ref="del" @clackok="deletVideo()">
      <box-2-item :it="it"></box-2-item>
    </delete>
  </div>
</template>

<script>
import Store from "../../../store";
import Box2Item from "../../home/box2-item.vue";
import Delete from "./delete.vue";
export default {
  components: { Delete, Box2Item },

  mounted() {},
  props: {
    it: {
      type: Object,
    },
    type: {
      type: Number,
    },
  },

  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {
    upDelet() {
      this.$refs.del.up();
    },
    // 删除视频
    async deletVideo() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        "/videoInfo/video/updateDelete",
        {
          userId: this.store.user.userId,
          videoId: this.it.id,
        }
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$emit("updete");
      return this.$message.success("删除成功");
    },
    // 恢复视频
    async updateVideo() {
      if (!this.store.user.userId) {
        return this.$message.warning("请先登录");
      }
      let { data: res } = await this.$http.post(
        "/videoInfo/video/relieveDelete",
        {
          userId: this.store.user.userId,
          videoId: this.it.id,
        }
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.$emit("updete");
      return this.$message.success("视频已经恢复");
    },
    // 前往视频详情
    goVideoDec() {
      this.store.actVideoId = this.it.id;
      this.$router.push("/video");
    },
  },
};
</script>

<style lang="less" scoped>
.itemBox {
  /* 矩形 14 */
  margin-top: 25px;
  display: flex;
  /* 矩形 6 */
  width: 100%;
  height: 170px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);
  .ind {
    /* 矩形 15 */
    background-image: url("../../../assets/images/logo2.png");
    background-size: 100% 100%;
    background: #debfbf;
    position: relative;
    /* 矩形 22 */

    width: 285px;
    height: 170px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(153, 153, 153, 0.3);

    img {
      width: 100%;
      height: 100%;
    }
    .start {
      font-size: 128px;
      position: absolute;
      width: 128px;
      width: 100%;
      text-align: center;
      background-color: #0006;
      height: 100%;
      line-height: 170px;
      svg {
        margin: 0 auto;
      }
      .in {
        color: #fff;
      }
      .err {
        color: #ff3a3a;
      }
    }
    .time {
      position: absolute;
      bottom: 12px;
      right: 13px;
      /* 34:16 */
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #ffffff;
    }
  }
  .dec {
    flex: 1;
    padding: 17px 38px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    .p {
      /* 稳定币发展历程稳定币发展历程稳定币发展历程... */
      height: 49px;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      margin-top: 10px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #333333;
      flex: 1;
    }
    .time {
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      margin-bottom: 16px;
    }
    .ft {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      height: 17px;
      font-family: SourceHanSansCN-Regular;
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0em;
      color: #999999;
      .l {
        flex: 1;
        display: flex;
        .item {
          margin-right: 53px;
          font-size: 16px;
        }
      }
      .r {
        display: flex;
        .item {
          margin-left: 53px;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
